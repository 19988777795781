import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '../pages/home.vue'),
  },
  {
    path: '/shop',
    name: 'shop',
    component: () => import(/* webpackChunkName: "shop" */ '../pages/shop.vue'),
  },
  // {
  //   path: '/maps',
  //   name: 'maps',
  //   component: () => import(/* webpackChunkName: "maps" */ '../pages/maps.vue'),
  // },
  // {
  //   path: '/podcast',
  //   name: 'podcast',
  //   component: () =>
  //     import(/* webpackChunkName: "podcast" */ '../pages/podcast.vue'),
  // },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
